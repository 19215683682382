import { ChakraProvider } from '@chakra-ui/react'
import React, { lazy, Suspense, useEffect, VFC } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Route, Switch, useLocation } from 'wouter'
import { DefaultLoading } from '../common/components/DefaultLoader'
import { useOptionStores } from '../common/store/useOptionStores'
import '../index.css'
import theme from '../varables/common/theme'
import { EmailChangeRequestForm } from './components/emailChangeRequest/EmailChangeRequestForm'
import { Header } from './components/Header'
import { createQueryClient } from '../common/createQueryClient'
import { onSignOut, useApplicant } from './hooks/useApplicant'
import EmailChangeComplete from './pages/EmailChangeComplete'
import EmailChangeRequestComplete from './pages/EmailChangeRequestComplete'
import Login from './pages/Login'
import { NotFound } from './pages/NotFound'
import PasswordChange from './pages/PasswordChange'
import PasswordChangeComplete from './pages/PasswordChangeComplete'
import PasswordReset from './pages/PasswordReset'
import PasswordResetComplete from './pages/PasswordResetComplete'
import PasswordResetRequest from './pages/PasswordResetRequest'
import PasswordResetRequestComplete from './pages/PasswordResetRequestComplete'
import Privacy from './pages/Privacy'
import Registration from './pages/Registration'
import RegistrationComplete from './pages/RegistrationComplete'
import RegistrationRequest from './pages/RegistrationRequest'
import RegistrationRequestComplete from './pages/RegistrationRequestComplete'

// lazy load は大きなコンポーネントだけにする。build 時に細かい chunk ができすぎる。
const Application = lazy(() => import('./pages/Application'))
const skipLogin =
  /^\/($|registrations|password-reset|privacy|change-email-requests|password-change-complete)/

const Index: VFC = () => {
  const [location, setLocation] = useLocation()
  const { loadOptions } = useOptionStores()
  const { state } = useApplicant()

  useEffect(() => {
    switch (state) {
      case 'signedIn':
        if (location === '/') {
          setLocation('/applications')
        }
        break
      case 'signedOut':
      case 'terminated':
        if (!skipLogin.test(location)) {
          setLocation('/')
        }
        break
    }
  }, [location, setLocation, state])

  useEffect(() => {
    loadOptions()
  }, [loadOptions])

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Suspense fallback={<DefaultLoading />}>
        {state === 'loading' ? (
          <DefaultLoading />
        ) : (
          <Switch>
            <Route path="/applications" component={Application} />
            <Route path="/change-email-requests/:id" component={EmailChangeComplete} />
            <Route path="/email-change-request-complete" component={EmailChangeRequestComplete} />
            <Route path="/email-change-request" component={EmailChangeRequestForm} />
            <Route path="/password-change" component={PasswordChange} />
            <Route path="/password-change-complete" component={PasswordChangeComplete} />
            <Route path="/password-reset-complete" component={PasswordResetComplete} />
            <Route path="/password-reset/:id" component={PasswordReset} />
            <Route
              path="/password-reset-request-complete"
              component={PasswordResetRequestComplete}
            />
            <Route path="/password-reset-request" component={PasswordResetRequest} />
            <Route path="/registrations-complete" component={RegistrationComplete} />
            <Route path="/registrations/:id" component={Registration} />
            <Route path="/registrations-request-complete" component={RegistrationRequestComplete} />
            <Route path="/registrations-request" component={RegistrationRequest} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/" component={Login} />
            <Route path="/:rest*" component={NotFound} />
          </Switch>
        )}
      </Suspense>
    </ChakraProvider>
  )
}

// ユーザーごとにキャッシュなど別管理したいため
const queryClient = createQueryClient(onSignOut)
export const ApplicantIndex: VFC = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Index />
  </QueryClientProvider>
)
