import React, { FC } from 'react'
import { Container } from '../../common/components/Container'

export const ContainerWithAnnounce: FC = ({ children }) => {
  return (
    <Container innerWidth={'800px'}>
      {/*<Announce*/}
      {/*  type={'warning'}*/}
      {/*  className={'mb24'}*/}
      {/*  title={'AAOの申請については、以下をご参照下さい。'}*/}
      {/*  date={'2020/11/06 10:20'}*/}
      {/*  body={*/}
      {/*    <div>*/}
      {/*      冬季休業に伴い、下記の期間AAO事務室は業務をお休みいたします。*/}
      {/*      <br />*/}
      {/*      2020年12月26日（土) ～2021年 1月3日（日）*/}
      {/*      <br />*/}
      {/*      <br />*/}
      {/*      ※上記期間中はメールへの返信はできませんので、ご了承ください。*/}
      {/*      <br />*/}
      {/*      ご理解、ご協力の程よろしくお願いいたします。*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*/>*/}
      {children}
    </Container>
  )
}
