import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const countriesStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadCountries() {
  await api.getCountries().then((result) => {
    countriesStore.items = result
  })
}

export function getCountryName(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return '' // todo 見直す
  return getName(countriesStore.items, id, defaultValue, isOther)
}

export function getCountryOptions(countries: {
  items: Option[]
}): Array<Option & { label: string; value: string }> {
  const key = isJa() ? 'nameJa' : 'name'
  const result = countries.items.map((c) => ({
    ...c,
    value: c.code,
    label: c[key],
  }))
  result.sort((a, b) => {
    if (a.isOther) return 1
    if (b.isOther) return -1
    if (a.label < b.label) {
      return -1
    } else if (a.label === b.label) {
      return 0
    } else {
      return 1
    }
  })
  return result
}
