import React, { VFC } from 'react'
import { ContainerWithAnnounce } from '../components/ContainerWithAnnounce'
import { PasswordResetRequestForm } from '../components/passwordReset/PasswordResetRequestForm'

const PasswordResetRequest: VFC = () => {
  return (
    <ContainerWithAnnounce>
      <PasswordResetRequestForm />
    </ContainerWithAnnounce>
  )
}

export default PasswordResetRequest
