import { Center } from '@chakra-ui/react'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTitle } from 'react-use'
import { useRoute } from 'wouter'
import { apiConfig } from '../../common/apiConfig'
import { Container } from '../../common/components/Container'
import { TextLink } from '../../common/components/TextLink'
import { ApplicantProfileApi } from '../../generated'
import { Title } from '../components/Title'
import { signOut } from '../hooks/useApplicant'

const EmailChangeComplete: FC = () => {
  const [t] = useTranslation()
  useTitle(t('PSS メールアドレス変更'))
  const [, params] = useRoute<{ id: string }>('/change-email-requests/:id')
  const token = params?.id

  useEffect(() => {
    if (!token) return
    new ApplicantProfileApi(apiConfig)
      .activateChangeEmail(token)
      .then(signOut)
      .catch((error) => {
        switch (error.status) {
          case 404:
            console.log('error', error)
            break
          case 409:
            // メールアドレスが今と同じ、または、他の申請者のアドレスと衝突したとき。409 Conflict
            console.log('error', error)
            break
          default:
            console.log('error', error)
        }
      })
  }, [token])

  return (
    <Container innerWidth={'800px'}>
      <Title text={t('メールアドレス変更')} />
      <div className={'mt24'}>
        {t('メールアドレス変更が完了しました。')}
        <br />
        {t('トップページからログインしてください。')}
      </div>
      <div className={'mt24'}>
        <Center className={'mt8'}>
          <TextLink text={t('トップページへ戻る')} to={'/'} />
        </Center>
      </div>
    </Container>
  )
}

export default EmailChangeComplete
