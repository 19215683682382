/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Teacher
 */
export interface Teacher {
    /**
     * 
     * @type {Date}
     * @memberof Teacher
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Teacher
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof Teacher
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Teacher
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    state: TeacherStateEnum;
    /**
     * 
     * @type {number}
     * @memberof Teacher
     */
    courseId?: number;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    publicEmail: string;
    /**
     * 
     * @type {string}
     * @memberof Teacher
     */
    memo?: string;
}

/**
* @export
* @enum {string}
*/
export enum TeacherStateEnum {
    Active = 'active',
    Hidden = 'hidden'
}

export function TeacherFromJSON(json: any): Teacher {
    return TeacherFromJSONTyped(json, false);
}

export function TeacherFromJSONTyped(json: any, ignoreDiscriminator: boolean): Teacher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'state': json['state'],
        'courseId': !exists(json, 'courseId') ? undefined : json['courseId'],
        'name': json['name'],
        'email': json['email'],
        'publicEmail': json['publicEmail'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
    };
}

export function TeacherToJSON(value?: Teacher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
        'state': value.state,
        'courseId': value.courseId,
        'name': value.name,
        'email': value.email,
        'publicEmail': value.publicEmail,
        'memo': value.memo,
    };
}


