import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const supplementalMaterialTypesStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadSupplementalMaterialTypes() {
  await api.getSupplementalMaterialTypes().then((result) => {
    supplementalMaterialTypesStore.items = result
  })
}

export function getSupplementalMaterialTypesName(id?: number, defaultValue?: string) {
  if (!id) return ''
  return getName(supplementalMaterialTypesStore.items, id, defaultValue)
}

export function getSupplementalMaterialTypesOptions(systems: { items: Option[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  return systems.items.map((c) => ({
    ...c,
    value: c.code,
    label: c[key],
  }))
}
