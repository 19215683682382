import { proxy } from 'valtio'
import { CommonApi, QuestionCategoryResponse, QuestionResponse } from '../../generated'
import { apiConfig } from '../apiConfig'
import { Lang } from '../i18n/useLang'

export const questionsStore = proxy<{
  questions: QuestionResponse[]
  categories: QuestionCategoryResponse[]
}>({
  questions: [],
  categories: [],
})

const api = new CommonApi(apiConfig)
export async function loadQuestions() {
  await api.getQuestions().then((result) => {
    questionsStore.questions = result
  })
}

export async function loadQuestionCategories() {
  await api.getQuestionCategories().then((result) => {
    questionsStore.categories = result
  })
}

export function getQuestions(id: number): QuestionResponse[] {
  return questionsStore.questions.filter((q) => q.questionCategoryId === id)
}

export function getCategories() {
  return questionsStore.categories
}
