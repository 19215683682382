import React, { VFC } from 'react'
import { ContainerWithAnnounce } from '../components/ContainerWithAnnounce'
import { RegistrationRequestForm } from '../components/registration/RegistrationRequestForm'

const RegistrationRequest: VFC = () => (
  <ContainerWithAnnounce>
    <RegistrationRequestForm />
  </ContainerWithAnnounce>
)

export default RegistrationRequest
