/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AcceptanceRecipient,
    AcceptanceRecipientFromJSON,
    AcceptanceRecipientFromJSONTyped,
    AcceptanceRecipientToJSON,
    AcceptanceRequest,
    AcceptanceRequestFromJSON,
    AcceptanceRequestFromJSONTyped,
    AcceptanceRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface Acceptance
 */
export interface Acceptance {
    /**
     * 
     * @type {Date}
     * @memberof Acceptance
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Acceptance
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof Acceptance
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Acceptance
     */
    deadline?: Date;
    /**
     * 
     * @type {number}
     * @memberof Acceptance
     */
    requestTemplateId?: number;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    requestSubject: string;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    requestBody: string;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    requestSignature?: string;
    /**
     * 
     * @type {number}
     * @memberof Acceptance
     */
    cancelTemplateId?: number;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    cancelSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    cancelBody?: string;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    cancelSignature?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Acceptance
     */
    accepted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Acceptance
     */
    rejectReasonId?: number;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    reasonEn?: string;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    reasonJa?: string;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    otherReason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Acceptance
     */
    onlineMeeting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    onlineMeetingType?: AcceptanceOnlineMeetingTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Acceptance
     */
    onlineMeetingCandidate1?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Acceptance
     */
    onlineMeetingCandidate2?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Acceptance
     */
    onlineMeetingCandidate3?: Date;
    /**
     * 
     * @type {string}
     * @memberof Acceptance
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Acceptance
     */
    observer: boolean;
    /**
     * 
     * @type {AcceptanceRequest}
     * @memberof Acceptance
     */
    request?: AcceptanceRequest;
    /**
     * 
     * @type {Array<AcceptanceRecipient>}
     * @memberof Acceptance
     */
    recipients: Array<AcceptanceRecipient>;
    /**
     * 
     * @type {Date}
     * @memberof Acceptance
     */
    acceptorDeadline?: Date;
}

/**
* @export
* @enum {string}
*/
export enum AcceptanceOnlineMeetingTypeEnum {
    Yes = 'yes',
    NoDoMyself = 'no_do_myself',
    No = 'no'
}

export function AcceptanceFromJSON(json: any): Acceptance {
    return AcceptanceFromJSONTyped(json, false);
}

export function AcceptanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Acceptance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'requestTemplateId': !exists(json, 'requestTemplateId') ? undefined : json['requestTemplateId'],
        'requestSubject': json['requestSubject'],
        'requestBody': json['requestBody'],
        'requestSignature': !exists(json, 'requestSignature') ? undefined : json['requestSignature'],
        'cancelTemplateId': !exists(json, 'cancelTemplateId') ? undefined : json['cancelTemplateId'],
        'cancelSubject': !exists(json, 'cancelSubject') ? undefined : json['cancelSubject'],
        'cancelBody': !exists(json, 'cancelBody') ? undefined : json['cancelBody'],
        'cancelSignature': !exists(json, 'cancelSignature') ? undefined : json['cancelSignature'],
        'accepted': !exists(json, 'accepted') ? undefined : json['accepted'],
        'rejectReasonId': !exists(json, 'rejectReasonId') ? undefined : json['rejectReasonId'],
        'reasonEn': !exists(json, 'reasonEn') ? undefined : json['reasonEn'],
        'reasonJa': !exists(json, 'reasonJa') ? undefined : json['reasonJa'],
        'otherReason': !exists(json, 'otherReason') ? undefined : json['otherReason'],
        'onlineMeeting': !exists(json, 'onlineMeeting') ? undefined : json['onlineMeeting'],
        'onlineMeetingType': !exists(json, 'onlineMeetingType') ? undefined : json['onlineMeetingType'],
        'onlineMeetingCandidate1': !exists(json, 'onlineMeetingCandidate1') ? undefined : (new Date(json['onlineMeetingCandidate1'])),
        'onlineMeetingCandidate2': !exists(json, 'onlineMeetingCandidate2') ? undefined : (new Date(json['onlineMeetingCandidate2'])),
        'onlineMeetingCandidate3': !exists(json, 'onlineMeetingCandidate3') ? undefined : (new Date(json['onlineMeetingCandidate3'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'observer': json['observer'],
        'request': !exists(json, 'request') ? undefined : AcceptanceRequestFromJSON(json['request']),
        'recipients': ((json['recipients'] as Array<any>).map(AcceptanceRecipientFromJSON)),
        'acceptorDeadline': !exists(json, 'acceptorDeadline') ? undefined : (new Date(json['acceptorDeadline'])),
    };
}

export function AcceptanceToJSON(value?: Acceptance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'requestTemplateId': value.requestTemplateId,
        'requestSubject': value.requestSubject,
        'requestBody': value.requestBody,
        'requestSignature': value.requestSignature,
        'cancelTemplateId': value.cancelTemplateId,
        'cancelSubject': value.cancelSubject,
        'cancelBody': value.cancelBody,
        'cancelSignature': value.cancelSignature,
        'accepted': value.accepted,
        'rejectReasonId': value.rejectReasonId,
        'reasonEn': value.reasonEn,
        'reasonJa': value.reasonJa,
        'otherReason': value.otherReason,
        'onlineMeeting': value.onlineMeeting,
        'onlineMeetingType': value.onlineMeetingType,
        'onlineMeetingCandidate1': value.onlineMeetingCandidate1 === undefined ? undefined : (value.onlineMeetingCandidate1.toISOString()),
        'onlineMeetingCandidate2': value.onlineMeetingCandidate2 === undefined ? undefined : (value.onlineMeetingCandidate2.toISOString()),
        'onlineMeetingCandidate3': value.onlineMeetingCandidate3 === undefined ? undefined : (value.onlineMeetingCandidate3.toISOString()),
        'note': value.note,
        'observer': value.observer,
        'request': AcceptanceRequestToJSON(value.request),
        'recipients': ((value.recipients as Array<any>).map(AcceptanceRecipientToJSON)),
        'acceptorDeadline': value.acceptorDeadline === undefined ? undefined : (value.acceptorDeadline.toISOString()),
    };
}


