import React, { VFC } from 'react'
import { Container } from '../../common/components/Container'
import { PasswordChangeForm } from '../components/passwordChange/PasswordChangeForm'

const PasswordChange: VFC = () => {
  return (
    <Container innerWidth={'800px'}>
      <PasswordChangeForm />
    </Container>
  )
}

export default PasswordChange
