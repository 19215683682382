import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const coursesStore = proxy<{ items: Option[] }>({
  items: [],
})

new CommonApi(apiConfig).getCourses().then((result) => {
  coursesStore.items = result
})

const api = new CommonApi(apiConfig)
export async function loadCourses() {
  await api.getCourses().then((result) => {
    coursesStore.items = result
  })
}

export function getCoursesName(id?: number, defaultValue?: string) {
  if (!id) return
  return getName(coursesStore.items, id, defaultValue)
}

export function getCoursesNameOptions(courses: { items: Option[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  const result = courses.items.map((c) => ({
    ...c,
    // value: c.code,
    label: c[key],
  }))
  result.sort((a, b) => {
    if (a.label < b.label) {
      return -1
    } else if (a.label === b.label) {
      return 0
    } else {
      return 1
    }
  })
  return result
}
