/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnswerRequest
 */
export interface AnswerRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AnswerRequest
     */
    accepted: boolean;
    /**
     * 
     * @type {number}
     * @memberof AnswerRequest
     */
    rejectReasonId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    otherReason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerRequest
     */
    onlineMeeting?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    onlineMeetingType?: AnswerRequestOnlineMeetingTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof AnswerRequest
     */
    onlineMeetingCandidate1?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AnswerRequest
     */
    onlineMeetingCandidate2?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AnswerRequest
     */
    onlineMeetingCandidate3?: Date;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    note?: string;
}

/**
* @export
* @enum {string}
*/
export enum AnswerRequestOnlineMeetingTypeEnum {
    Yes = 'yes',
    NoDoMyself = 'no_do_myself',
    No = 'no'
}

export function AnswerRequestFromJSON(json: any): AnswerRequest {
    return AnswerRequestFromJSONTyped(json, false);
}

export function AnswerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accepted': json['accepted'],
        'rejectReasonId': !exists(json, 'rejectReasonId') ? undefined : json['rejectReasonId'],
        'otherReason': !exists(json, 'otherReason') ? undefined : json['otherReason'],
        'onlineMeeting': !exists(json, 'onlineMeeting') ? undefined : json['onlineMeeting'],
        'onlineMeetingType': !exists(json, 'onlineMeetingType') ? undefined : json['onlineMeetingType'],
        'onlineMeetingCandidate1': !exists(json, 'onlineMeetingCandidate1') ? undefined : (new Date(json['onlineMeetingCandidate1'])),
        'onlineMeetingCandidate2': !exists(json, 'onlineMeetingCandidate2') ? undefined : (new Date(json['onlineMeetingCandidate2'])),
        'onlineMeetingCandidate3': !exists(json, 'onlineMeetingCandidate3') ? undefined : (new Date(json['onlineMeetingCandidate3'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function AnswerRequestToJSON(value?: AnswerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accepted': value.accepted,
        'rejectReasonId': value.rejectReasonId,
        'otherReason': value.otherReason,
        'onlineMeeting': value.onlineMeeting,
        'onlineMeetingType': value.onlineMeetingType,
        'onlineMeetingCandidate1': value.onlineMeetingCandidate1 === undefined ? undefined : (value.onlineMeetingCandidate1.toISOString()),
        'onlineMeetingCandidate2': value.onlineMeetingCandidate2 === undefined ? undefined : (value.onlineMeetingCandidate2.toISOString()),
        'onlineMeetingCandidate3': value.onlineMeetingCandidate3 === undefined ? undefined : (value.onlineMeetingCandidate3.toISOString()),
        'note': value.note,
    };
}


