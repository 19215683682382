/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRequest,
    CreateRequestFromJSON,
    CreateRequestToJSON,
    FinishRequest,
    FinishRequestFromJSON,
    FinishRequestToJSON,
    Target,
    TargetFromJSON,
    TargetToJSON,
} from '../models';

export interface CreateOperationRequest {
    createRequest: CreateRequest;
}

export interface FinishOperationRequest {
    token: string;
    finishRequest: FinishRequest;
}

export interface GetRegistrationRequest {
    token: string;
}

/**
 * 
 */
export class ApplicantRegistrationsApi extends runtime.BaseAPI {

    /**
     */
    async createRaw(requestParameters: CreateOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createRequest === null || requestParameters.createRequest === undefined) {
            throw new runtime.RequiredError('createRequest','Required parameter requestParameters.createRequest was null or undefined when calling create.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applicant/registrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRequestToJSON(requestParameters.createRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async create(createRequest: CreateRequest): Promise<void> {
        await this.createRaw({ createRequest: createRequest });
    }

    /**
     */
    async finishRaw(requestParameters: FinishOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling finish.');
        }

        if (requestParameters.finishRequest === null || requestParameters.finishRequest === undefined) {
            throw new runtime.RequiredError('finishRequest','Required parameter requestParameters.finishRequest was null or undefined when calling finish.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applicant/registrations/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: FinishRequestToJSON(requestParameters.finishRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async finish(token: string, finishRequest: FinishRequest): Promise<void> {
        await this.finishRaw({ token: token, finishRequest: finishRequest });
    }

    /**
     */
    async getRegistrationRaw(requestParameters: GetRegistrationRequest): Promise<runtime.ApiResponse<Target>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling getRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applicant/registrations/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TargetFromJSON(jsonValue));
    }

    /**
     */
    async getRegistration(token: string): Promise<Target> {
        const response = await this.getRegistrationRaw({ token: token });
        return await response.value();
    }

}
