import React from 'react'
import {render} from 'react-dom'
import {ErrorBoundary} from '../common/components/ErrorBoundary'
import {initSentry} from "../common/sentry";
import '../index.css'
import {ApplicantIndex} from './index'

initSentry()

render(
  <ErrorBoundary>
    <ApplicantIndex />
  </ErrorBoundary>,
  document.getElementById('root')
)
