import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Button as ChakraButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import React, { forwardRef, useCallback, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

export const PasswordInput = forwardRef<HTMLInputElement, UseFormRegisterReturn & InputProps>(
  ({ ...props }, ref) => {
    const [show, setShow] = useState(false)
    const toggle = useCallback(() => setShow((show) => !show), [setShow])
    return (
      <InputGroup w={props.w}>
        <Input
          {...props}
          ref={ref}
          pr="4.5em"
          type={show ? 'text' : 'password'}
          autoComplete={props.autoComplete}
          maxLength={10000}
        />
        <InputRightElement h="100%" w="2em" pr="12px">
          <ChakraButton h="1.5em" w={'1em'} size={'sm'} onClick={toggle}>
            {show ? <ViewOffIcon /> : <ViewIcon />}
          </ChakraButton>
        </InputRightElement>
      </InputGroup>
    )
  }
)
