/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSignInRequest
 */
export interface UserSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof UserSignInRequest
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof UserSignInRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserSignInRequest
     */
    role: UserSignInRequestRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserSignInRequest
     */
    keep: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UserSignInRequestRoleEnum {
    Aao = 'aao',
    Approve = 'approve',
    Office = 'office'
}

export function UserSignInRequestFromJSON(json: any): UserSignInRequest {
    return UserSignInRequestFromJSONTyped(json, false);
}

export function UserSignInRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSignInRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': json['account'],
        'password': json['password'],
        'role': json['role'],
        'keep': json['keep'],
    };
}

export function UserSignInRequestToJSON(value?: UserSignInRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account,
        'password': value.password,
        'role': value.role,
        'keep': value.keep,
    };
}


