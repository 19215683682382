import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const scholarshipFinancialSupportsStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadScholarshipFinancialSupports() {
  await api.getScholarshipFinancialSupports().then((result) => {
    scholarshipFinancialSupportsStore.items = result
  })
}

export function getScholarshipFinancialSupportsName(id?: number, defaultValue?: string) {
  if (id === undefined) return ''
  return getName(scholarshipFinancialSupportsStore.items, id, defaultValue)
}

export function getScholarshipFinancialSupportsOptions(scholarshipFinancialSupports: {
  items: Option[]
}) {
  const key = isJa() ? 'nameJa' : 'name'
  const result = scholarshipFinancialSupports.items.map((e) => ({
    ...e,
    label: e[key],
  }))
  result.sort((a, b) => {
    if (a.isOther) return 1
    if (b.isOther) return -1
    if (a.id < b.id) {
      return -1
    } else if (a.id === b.id) {
      return 0
    } else {
      return 1
    }
  })
  return result
}
