import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'
import clsx from 'clsx'
import React, { FC } from 'react'
import classes from './button.module.css'
import { useButtonStyle } from './useButtonStyle'

export interface ButtonP extends ButtonProps {
  role: 'primary' | 'destructive' | 'secondary' | 'warning' | 'disable' | 'link'
  text: string
  className?: string
  invalid?: boolean
}

export const Button: FC<ButtonP> = ({ role, text, disabled, invalid, className, ...props }) => {
  const overrideStyle = useButtonStyle(role)

  return (
    <ChakraButton
      className={clsx(classes.root, className, invalid && classes.invalid)}
      isDisabled={role === 'disable' || disabled}
      {...overrideStyle}
      {...props}
    >
      {text}
    </ChakraButton>
  )
}
