import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { Lang } from '../i18n/useLang'
import { getName } from '../util/getName'

export const scholarshipStatusStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadScholarshipStatuses() {
  await api.getScholarshipStatuses().then((result) => {
    scholarshipStatusStore.items = result
  })
}

export function getScholarshipStatusName(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return ''
  return getName(scholarshipStatusStore.items, id, defaultValue, isOther)
}

export function getScholarshipStatusOptions(systems: { items: Option[] }, language: Lang) {
  const key = language === 'ja' ? 'nameJa' : 'name'
  return systems.items.map((c) => ({
    ...c,
    value: c.code,
    label: c[key],
  }))
}
