/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CurrentApplicant,
    CurrentApplicantFromJSON,
    CurrentApplicantToJSON,
    SignInRequest,
    SignInRequestFromJSON,
    SignInRequestToJSON,
} from '../models';

export interface SignInOperationRequest {
    signInRequest: SignInRequest;
}

/**
 * 
 */
export class ApplicantSessionsApi extends runtime.BaseAPI {

    /**
     */
    async currentRaw(): Promise<runtime.ApiResponse<CurrentApplicant>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applicant/sessions/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentApplicantFromJSON(jsonValue));
    }

    /**
     */
    async current(): Promise<CurrentApplicant> {
        const response = await this.currentRaw();
        return await response.value();
    }

    /**
     */
    async signInRaw(requestParameters: SignInOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signInRequest === null || requestParameters.signInRequest === undefined) {
            throw new runtime.RequiredError('signInRequest','Required parameter requestParameters.signInRequest was null or undefined when calling signIn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applicant/sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInRequestToJSON(requestParameters.signInRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async signIn(signInRequest: SignInRequest): Promise<void> {
        await this.signInRaw({ signInRequest: signInRequest });
    }

    /**
     */
    async signOutRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applicant/sessions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async signOut(): Promise<void> {
        await this.signOutRaw();
    }

}
