import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { Lang } from '../i18n/useLang'
import { getName } from '../util/getName'

export const educationSystemsStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadEducationalSystems() {
  await api.getEducationalSystems().then((result) => {
    educationSystemsStore.items = result
  })
}

export function getEducationSystemsName(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return ''
  return getName(educationSystemsStore.items, id, defaultValue, isOther)
}

export function getEducationalSystemsOptions(systems: { items: Option[] }, language: Lang) {
  // const key = isJa() ? 'nameJa' : 'name'
  const key = language === 'ja' ? 'nameJa' : 'name'
  const result = systems.items.map((c) => ({
    ...c,
    value: c.code,
    label: c[key],
  }))
  result.sort((a, b) => {
    if (a.isOther) return 1
    if (b.isOther) return -1
    if (a.label < b.label) {
      return -1
    } else if (a.label === b.label) {
      return 0
    } else {
      return 1
    }
  })

  return result
}
