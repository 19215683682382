/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    role: CreateUserRequestRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    password: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateUserRequestRoleEnum {
    Aao = 'aao',
    Approve = 'approve',
    Office = 'office'
}

export function CreateUserRequestFromJSON(json: any): CreateUserRequest {
    return CreateUserRequestFromJSONTyped(json, false);
}

export function CreateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': json['account'],
        'name': json['name'],
        'email': json['email'],
        'role': json['role'],
        'password': json['password'],
    };
}

export function CreateUserRequestToJSON(value?: CreateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account,
        'name': value.name,
        'email': value.email,
        'role': value.role,
        'password': value.password,
    };
}


