import { createMachine, interpret, MachineState, SendFunction, state, transition } from 'robot3'
import { proxy, useSnapshot } from 'valtio'

interface States {
  loading: MachineState
  signedIn: MachineState
  signedOut: MachineState
  terminated: MachineState
}
type Transitions = 'onSuccess' | 'onError' | 'signIn' | 'signOut'

function tr(event: Transitions, toState: keyof States) {
  return transition(event, toState)
}

const machine = createMachine<States>('loading', {
  loading: state(tr('onSuccess', 'signedIn'), tr('onError', 'signedOut')),
  signedIn: state(tr('onError', 'terminated'), tr('signOut', 'signedOut')),
  signedOut: state(tr('signIn', 'signedIn'), tr('onSuccess', 'signedIn')),
  terminated: state(tr('signIn', 'signedIn'), tr('onSuccess', 'signedIn')),
})

export type State = keyof States
export type OnSignOut = (state: State) => void

export function createSessionState(
  _signIn: (id: string, pw: string, keep: boolean) => Promise<void>,
  _signOut: () => Promise<void>
) {
  // valtio 必須ではないけど自分で listener 管理しなくてよくなる
  // useEffect での listener 開放も任せられるのが嬉しい点
  const session = proxy<{ state: State }>({
    state: machine.current,
  })

  let onSignOuts: OnSignOut[] = []

  /**
   * ログアウトやセッション切断は特別でローカルで管理している様々な情報をリセットしなければならない
   * 単純に valtio の subscribe が使えないのは前の状態と比較しないといけないため。
   */
  function onSignOut(callback: OnSignOut): () => void {
    onSignOuts = onSignOuts ? [...onSignOuts, callback] : []
    return () => {
      onSignOuts = onSignOuts.filter((f) => f !== callback)
    }
  }

  let prev: State = machine.current
  const service = interpret(machine, (service) => {
    // console.debug(`session transition: prev=${prev} state=${service.machine.current}`)
    const next = service.machine.current
    session.state = next
    if (prev === 'signedIn' && (next === 'terminated' || next === 'signedOut')) {
      onSignOuts.forEach((fn) => fn(next))
    }
    prev = next
  })

  const send: SendFunction<Transitions> = service.send.bind(service)

  async function signIn(email: string, password: string, keep = false) {
    await _signIn(email, password, keep)
    send('signIn') // 成功したら signIn
  }

  // 成功したら signOut 遷移。
  // でないと api 呼び出し前に swr の自動 revalidate で onSuccess して、その後 signOut になってしまう。
  async function signOut() {
    await _signOut()
    send('signOut')
  }

  function onError(r?: Response) {
    if (!r) return
    if (r instanceof Response && r.status === 403) {
      send('onError')
    }
  }

  function useSession(): State {
    return useSnapshot(session).state
  }

  return {
    useSession,
    signIn,
    signOut,
    onSignOut,
    onError,
    send,
  }
}
