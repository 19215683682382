import { Configuration } from '../generated'

const MAX_RAND = Math.pow(2, 12) - 1
const EPOCH = new Date("2021-01-01T00:00:00.000Z").getTime()

function rand(): number {
  for (let i = 0; i < 1000; i++) {
    const ia = new Uint16Array(1)
    crypto.getRandomValues(ia)
    const value = Math.abs(ia[0])
    if (value <= MAX_RAND) {
      return value
    }
  }
  throw Error(`can't generate valid random less than ${MAX_RAND}`)
}

function newId(): string {
  const ts = new Date().getTime() - EPOCH
  return `${ts}:${rand()}`
}

export const apiConfig = new Configuration({
  basePath: location.origin,
  credentials: 'same-origin',
  middleware: [
    {
      pre: async (context) => {
        const id = newId()
        if (context.init.headers === undefined) {
          context.init.headers = {
            'X-Request-ID': id,
            Accept: 'application/json',
          }
        } else {
          const headers = context.init.headers as Record<string, string>
          headers['X-Request-Id'] = id
          headers['Accept'] = 'application/json'
        }
      },
    },
  ],
})
