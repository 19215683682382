import { proxy } from 'valtio'
import { CommonApi, Option, OptionGroup } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const programsStore = proxy<{ items: OptionGroup[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadPrograms() {
  await api.getPrograms().then((result) => {
    programsStore.items = result
  })
}

export function getProgramsName(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return ``
  const items: Option[] = []
  programsStore.items.forEach((p: OptionGroup) =>
    p.options.forEach((p: Option) => {
      items.push(p)
    })
  )
  return getName(items, id, defaultValue, isOther)
}

export function getProgramsOptions(programs: { items: OptionGroup[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  const result = programs.items.map((c) => ({
    label: c[key],
    options: c.options.map((o) => ({
      ...o,
      value: o.code,
      label: o[key],
    })),
  }))
  result.forEach((r) => {
    r.options.sort((a, b) => {
      if (a.isOther) return 1
      if (b.isOther) return -1
      if (a.label < b.label) {
        return -1
      } else if (a.label === b.label) {
        return 0
      } else {
        return 1
      }
    })
  })
  return result
}
