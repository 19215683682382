/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationSearchCriteria
 */
export interface ApplicationSearchCriteria {
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchCriteria
     */
    officeId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationSearchCriteria
     */
    states?: Array<ApplicationSearchCriteriaStatesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    reentry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    resubmit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    approved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    relayAll?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchCriteria
     */
    binderId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchCriteria
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchCriteria
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchCriteria
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchCriteria
     */
    courseId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchCriteria
     */
    supervisor?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchCriteria
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchCriteria
     */
    university?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchCriteria
     */
    firstSentFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchCriteria
     */
    firstSentTo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchCriteria
     */
    sentFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchCriteria
     */
    sentTo?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchCriteria
     */
    repliedFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSearchCriteria
     */
    repliedTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchCriteria
     */
    keyword?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    includeOld?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    all: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchCriteria
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationSearchCriteria
     */
    limit: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSearchCriteria
     */
    orderBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationSearchCriteria
     */
    desc: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ApplicationSearchCriteriaStatesEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    Old = 'Old'
}

export function ApplicationSearchCriteriaFromJSON(json: any): ApplicationSearchCriteria {
    return ApplicationSearchCriteriaFromJSONTyped(json, false);
}

export function ApplicationSearchCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationSearchCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'officeId': !exists(json, 'officeId') ? undefined : json['officeId'],
        'states': !exists(json, 'states') ? undefined : json['states'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'reentry': !exists(json, 'reentry') ? undefined : json['reentry'],
        'resubmit': !exists(json, 'resubmit') ? undefined : json['resubmit'],
        'approved': !exists(json, 'approved') ? undefined : json['approved'],
        'relayAll': !exists(json, 'relayAll') ? undefined : json['relayAll'],
        'binderId': !exists(json, 'binderId') ? undefined : json['binderId'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'courseId': !exists(json, 'courseId') ? undefined : json['courseId'],
        'supervisor': !exists(json, 'supervisor') ? undefined : json['supervisor'],
        'countryId': !exists(json, 'countryId') ? undefined : json['countryId'],
        'university': !exists(json, 'university') ? undefined : json['university'],
        'firstSentFrom': !exists(json, 'firstSentFrom') ? undefined : (new Date(json['firstSentFrom'])),
        'firstSentTo': !exists(json, 'firstSentTo') ? undefined : (new Date(json['firstSentTo'])),
        'sentFrom': !exists(json, 'sentFrom') ? undefined : (new Date(json['sentFrom'])),
        'sentTo': !exists(json, 'sentTo') ? undefined : (new Date(json['sentTo'])),
        'repliedFrom': !exists(json, 'repliedFrom') ? undefined : (new Date(json['repliedFrom'])),
        'repliedTo': !exists(json, 'repliedTo') ? undefined : (new Date(json['repliedTo'])),
        'keyword': !exists(json, 'keyword') ? undefined : json['keyword'],
        'includeOld': !exists(json, 'includeOld') ? undefined : json['includeOld'],
        'all': json['all'],
        'offset': json['offset'],
        'limit': json['limit'],
        'orderBy': !exists(json, 'orderBy') ? undefined : json['orderBy'],
        'desc': json['desc'],
    };
}

export function ApplicationSearchCriteriaToJSON(value?: ApplicationSearchCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'officeId': value.officeId,
        'states': value.states,
        'hidden': value.hidden,
        'reentry': value.reentry,
        'resubmit': value.resubmit,
        'approved': value.approved,
        'relayAll': value.relayAll,
        'binderId': value.binderId,
        'familyName': value.familyName,
        'givenName': value.givenName,
        'email': value.email,
        'courseId': value.courseId,
        'supervisor': value.supervisor,
        'countryId': value.countryId,
        'university': value.university,
        'firstSentFrom': value.firstSentFrom === undefined ? undefined : (value.firstSentFrom.toISOString()),
        'firstSentTo': value.firstSentTo === undefined ? undefined : (value.firstSentTo.toISOString()),
        'sentFrom': value.sentFrom === undefined ? undefined : (value.sentFrom.toISOString()),
        'sentTo': value.sentTo === undefined ? undefined : (value.sentTo.toISOString()),
        'repliedFrom': value.repliedFrom === undefined ? undefined : (value.repliedFrom.toISOString()),
        'repliedTo': value.repliedTo === undefined ? undefined : (value.repliedTo.toISOString()),
        'keyword': value.keyword,
        'includeOld': value.includeOld,
        'all': value.all,
        'offset': value.offset,
        'limit': value.limit,
        'orderBy': value.orderBy,
        'desc': value.desc,
    };
}


