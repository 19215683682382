import isEqual from 'react-fast-compare'
import { QueryClient } from 'react-query'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function nop(callback: () => void) {
  console.info('callback is ignored')
}

export function createQueryClient(
  onSignOut: (callback: () => void) => void = nop,
  cacheTime = 5 * 60_000
): QueryClient {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 2000, // SWR と同じ
        cacheTime,
        retry: false,
        notifyOnChangeProps: 'tracked',
        // ReactQuery は Date があると別オブジェクトとみなす
        isDataEqual: isEqual,
      },
    },
  })

  onSignOut(() => {
    client.clear()
  })

  return client
}
