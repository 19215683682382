import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const partnerSchoolTypeStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadPartnerSchoolTypes() {
  await api.getPartnerSchoolTypes().then((result) => {
    partnerSchoolTypeStore.items = result
  })
}

export function getPartnerSchoolTypeStore(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return '' // todo 見直す
  return getName(partnerSchoolTypeStore.items, id, defaultValue, isOther)
}

export function getPartnerSchoolTypeOptions(partnerSchoolType: { items: Option[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  return partnerSchoolType.items.map((c) => ({
    ...c,
    value: c.code,
    label: c[key],
  }))
}
