import { Spinner } from '@chakra-ui/react'
import React, { memo, VFC } from 'react'
import classes from './style.module.css'

const _DefaultLoading: VFC = () => {
  return (
    <div className={classes.root}>
      <Spinner
        thickness="4px"
        speed="0.75s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </div>
  )
}

export const DefaultLoading = memo(_DefaultLoading)
