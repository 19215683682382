/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {Date}
     * @memberof Course
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof Course
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    state: CourseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    nameEn: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    shortName: string;
}

/**
* @export
* @enum {string}
*/
export enum CourseStateEnum {
    Active = 'active',
    Hidden = 'hidden'
}

export function CourseFromJSON(json: any): Course {
    return CourseFromJSONTyped(json, false);
}

export function CourseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Course {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'state': json['state'],
        'name': json['name'],
        'nameEn': json['nameEn'],
        'shortName': json['shortName'],
    };
}

export function CourseToJSON(value?: Course | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
        'state': value.state,
        'name': value.name,
        'nameEn': value.nameEn,
        'shortName': value.shortName,
    };
}


