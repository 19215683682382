/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Office,
    OfficeFromJSON,
    OfficeFromJSONTyped,
    OfficeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OfficeUser
 */
export interface OfficeUser {
    /**
     * 
     * @type {Date}
     * @memberof OfficeUser
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof OfficeUser
     */
    version: number;
    /**
     * 
     * @type {Date}
     * @memberof OfficeUser
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof OfficeUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OfficeUser
     */
    role: OfficeUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeUser
     */
    account: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeUser
     */
    name: string;
    /**
     * 
     * @type {Office}
     * @memberof OfficeUser
     */
    office?: Office;
}

/**
* @export
* @enum {string}
*/
export enum OfficeUserRoleEnum {
    Aao = 'aao',
    Approve = 'approve',
    Office = 'office'
}

export function OfficeUserFromJSON(json: any): OfficeUser {
    return OfficeUserFromJSONTyped(json, false);
}

export function OfficeUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficeUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'version': json['version'],
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
        'role': json['role'],
        'account': json['account'],
        'email': json['email'],
        'name': json['name'],
        'office': !exists(json, 'office') ? undefined : OfficeFromJSON(json['office']),
    };
}

export function OfficeUserToJSON(value?: OfficeUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'version': value.version,
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
        'role': value.role,
        'account': value.account,
        'email': value.email,
        'name': value.name,
        'office': OfficeToJSON(value.office),
    };
}


