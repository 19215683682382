/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationVersion
 */
export interface ApplicationVersion {
    /**
     * 
     * @type {number}
     * @memberof ApplicationVersion
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationVersion
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationVersion
     */
    state: ApplicationVersionStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationVersion
     */
    resubmit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationVersion
     */
    reentry: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationVersion
     */
    sentAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationVersion
     */
    createdAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum ApplicationVersionStateEnum {
    Draft = 'Draft',
    Sent = 'Sent',
    Reviewing = 'Reviewing',
    ApproveRequested = 'ApproveRequested',
    ApproveCanceled = 'ApproveCanceled',
    ApproveAccepted = 'ApproveAccepted',
    ApproveRejected = 'ApproveRejected',
    RelayRequested = 'RelayRequested',
    RelayCanceled = 'RelayCanceled',
    RelayTeacherRequested = 'RelayTeacherRequested',
    RelayTeacherCanceled = 'RelayTeacherCanceled',
    RelayTeacherAccepted = 'RelayTeacherAccepted',
    RelayTeacherRejected = 'RelayTeacherRejected',
    RelayAccepted = 'RelayAccepted',
    RelayRejected = 'RelayRejected',
    Direct = 'Direct',
    CanceledReentryAllowed = 'CanceledReentryAllowed',
    Canceled = 'Canceled',
    Accepted = 'Accepted',
    RejectedReentryAllowed = 'RejectedReentryAllowed',
    Rejected = 'Rejected',
    Old = 'Old'
}

export function ApplicationVersionFromJSON(json: any): ApplicationVersion {
    return ApplicationVersionFromJSONTyped(json, false);
}

export function ApplicationVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'],
        'state': json['state'],
        'resubmit': json['resubmit'],
        'reentry': json['reentry'],
        'sentAt': !exists(json, 'sentAt') ? undefined : (new Date(json['sentAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ApplicationVersionToJSON(value?: ApplicationVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'state': value.state,
        'resubmit': value.resubmit,
        'reentry': value.reentry,
        'sentAt': value.sentAt === undefined ? undefined : (value.sentAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}


