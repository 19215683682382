/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface University
 */
export interface University {
    /**
     * 
     * @type {number}
     * @memberof University
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    nameKana?: string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    nameEn?: string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    nameLocal?: string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    kind?: string;
    /**
     * 
     * @type {string}
     * @memberof University
     */
    countryName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof University
     */
    partnerSchool: boolean;
}

export function UniversityFromJSON(json: any): University {
    return UniversityFromJSONTyped(json, false);
}

export function UniversityFromJSONTyped(json: any, ignoreDiscriminator: boolean): University {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'name': json['name'],
        'nameKana': !exists(json, 'nameKana') ? undefined : json['nameKana'],
        'nameEn': !exists(json, 'nameEn') ? undefined : json['nameEn'],
        'nameLocal': !exists(json, 'nameLocal') ? undefined : json['nameLocal'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
        'countryName': !exists(json, 'countryName') ? undefined : json['countryName'],
        'partnerSchool': json['partnerSchool'],
    };
}

export function UniversityToJSON(value?: University | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'name': value.name,
        'nameKana': value.nameKana,
        'nameEn': value.nameEn,
        'nameLocal': value.nameLocal,
        'note': value.note,
        'kind': value.kind,
        'countryName': value.countryName,
        'partnerSchool': value.partnerSchool,
    };
}


