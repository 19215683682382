/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Purpose
 */
export interface Purpose {
    /**
     * 
     * @type {number}
     * @memberof Purpose
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Purpose
     */
    role: PurposeRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Purpose
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Purpose
     */
    name: string;
}

/**
* @export
* @enum {string}
*/
export enum PurposeRoleEnum {
    Aao = 'aao',
    Approve = 'approve',
    Office = 'office'
}

export function PurposeFromJSON(json: any): Purpose {
    return PurposeFromJSONTyped(json, false);
}

export function PurposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Purpose {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'role': json['role'],
        'code': json['code'],
        'name': json['name'],
    };
}

export function PurposeToJSON(value?: Purpose | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': value.role,
        'code': value.code,
        'name': value.name,
    };
}


