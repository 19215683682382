/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangeEmailRequest,
    ChangeEmailRequestFromJSON,
    ChangeEmailRequestToJSON,
    EmailChangeResult,
    EmailChangeResultFromJSON,
    EmailChangeResultToJSON,
    PasswordChangeRequest,
    PasswordChangeRequestFromJSON,
    PasswordChangeRequestToJSON,
} from '../models';

export interface ActivateChangeEmailRequest {
    token: string;
}

export interface RequestChangeEmailRequest {
    changeEmailRequest: ChangeEmailRequest;
}

export interface UpdatePasswordRequest {
    passwordChangeRequest: PasswordChangeRequest;
}

/**
 * 
 */
export class ApplicantProfileApi extends runtime.BaseAPI {

    /**
     * メールアドレス変更の確定
     */
    async activateChangeEmailRaw(requestParameters: ActivateChangeEmailRequest): Promise<runtime.ApiResponse<EmailChangeResult>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling activateChangeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applicant/email-change-requests/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailChangeResultFromJSON(jsonValue));
    }

    /**
     * メールアドレス変更の確定
     */
    async activateChangeEmail(token: string): Promise<EmailChangeResult> {
        const response = await this.activateChangeEmailRaw({ token: token });
        return await response.value();
    }

    /**
     * メールアドレス変更リクエスト
     */
    async requestChangeEmailRaw(requestParameters: RequestChangeEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changeEmailRequest === null || requestParameters.changeEmailRequest === undefined) {
            throw new runtime.RequiredError('changeEmailRequest','Required parameter requestParameters.changeEmailRequest was null or undefined when calling requestChangeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applicant/email-change-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeEmailRequestToJSON(requestParameters.changeEmailRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メールアドレス変更リクエスト
     */
    async requestChangeEmail(changeEmailRequest: ChangeEmailRequest): Promise<void> {
        await this.requestChangeEmailRaw({ changeEmailRequest: changeEmailRequest });
    }

    /**
     * パスワード変更
     */
    async updatePasswordRaw(requestParameters: UpdatePasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordChangeRequest === null || requestParameters.passwordChangeRequest === undefined) {
            throw new runtime.RequiredError('passwordChangeRequest','Required parameter requestParameters.passwordChangeRequest was null or undefined when calling updatePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/applicant/password`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordChangeRequestToJSON(requestParameters.passwordChangeRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * パスワード変更
     */
    async updatePassword(passwordChangeRequest: PasswordChangeRequest): Promise<void> {
        await this.updatePasswordRaw({ passwordChangeRequest: passwordChangeRequest });
    }

}
