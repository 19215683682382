/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Office,
    OfficeFromJSON,
    OfficeFromJSONTyped,
    OfficeToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelayRecipient
 */
export interface RelayRecipient {
    /**
     * 
     * @type {string}
     * @memberof RelayRecipient
     */
    type: RelayRecipientTypeEnum;
    /**
     * 
     * @type {Office}
     * @memberof RelayRecipient
     */
    office: Office;
    /**
     * 
     * @type {User}
     * @memberof RelayRecipient
     */
    user: User;
    /**
     * 
     * @type {boolean}
     * @memberof RelayRecipient
     */
    observer: boolean;
}

/**
* @export
* @enum {string}
*/
export enum RelayRecipientTypeEnum {
    Main = 'Main',
    Observer = 'Observer'
}

export function RelayRecipientFromJSON(json: any): RelayRecipient {
    return RelayRecipientFromJSONTyped(json, false);
}

export function RelayRecipientFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelayRecipient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'office': OfficeFromJSON(json['office']),
        'user': UserFromJSON(json['user']),
        'observer': json['observer'],
    };
}

export function RelayRecipientToJSON(value?: RelayRecipient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'office': OfficeToJSON(value.office),
        'user': UserToJSON(value.user),
        'observer': value.observer,
    };
}


