import { proxy } from 'valtio'
import { CommonApi, Option } from '../../generated'
import { apiConfig } from '../apiConfig'
import { isJa } from '../i18n/useLang'
import { getName } from '../util/getName'

export const scholarshipTypesStore = proxy<{ items: Option[] }>({
  items: [],
})

const api = new CommonApi(apiConfig)
export async function loadScholarshipTypes() {
  await api.getScholarshipTypes().then((result) => {
    scholarshipTypesStore.items = result
  })
}

export function getScholarshipTypesName(id?: number, defaultValue?: string, isOther?: boolean) {
  if (id === undefined) return ''
  return getName(scholarshipTypesStore.items, id, defaultValue, isOther)
}

export function getScholarshipTypesOptions(scholarshipTypes: { items: Option[] }) {
  const key = isJa() ? 'nameJa' : 'name'
  const result = scholarshipTypes.items.map((e) => ({
    ...e,
    label: e[key],
  }))
  result.sort((a, b) => {
    if (a.isOther) return 1
    if (b.isOther) return -1
    if (a.id < b.id) {
      return -1
    } else if (a.id === b.id) {
      return 0
    } else {
      return 1
    }
  })
  return result
}
