import {
  ChangeHandler,
  Control,
  FieldError,
  FieldPath,
  get,
  RegisterOptions,
  UseFormRegisterReturn,
  useFormState,
} from 'react-hook-form'
import { FieldPathValue } from 'react-hook-form/dist/types'
import { zenToHan } from '../util/zenToHan'

export type FormProps<T> = {
  control: Control<T>
  path: FieldPath<T>
  defaultValue?: FieldPathValue<T, FieldPath<T>>
  options?: RegisterOptions
  skipNormalize?: boolean
}

export interface ErrorRefCallbackHandler extends UseFormRegisterReturn {
  id: string
  isInvalid: boolean
  error?: FieldError
}

function normalize(value: string | null | undefined): string {
  if (value === undefined) return ''
  if (value === null) return ''
  return zenToHan(value.trim())
}

function normalizedOnBlur(onBlur: ChangeHandler): ChangeHandler {
  return (e: FocusEvent) => {
    if (e.currentTarget) {
      const t = e.currentTarget as HTMLInputElement
      t.value = normalize(t.value)
    }
    return onBlur(e)
  }
}

export function useFormError<T>(control: Control<T>, path: FieldPath<T>) {
  const { errors } = useFormState({ control, name: path })
  const error = get(errors, path)
  return { error, isInvalid: !!error }
}

export function useFormProps<T>({
  control,
  path,
  options,
  skipNormalize,
}: FormProps<T>): ErrorRefCallbackHandler {
  const { onBlur, ...props } = control.register(path, options)
  const { error, isInvalid } = useFormError(control, path)
  return {
    id: path as string,
    isInvalid,
    onBlur: skipNormalize ? onBlur : normalizedOnBlur(onBlur),
    error,
    ...props,
  }
}
