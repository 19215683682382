/* tslint:disable */
/* eslint-disable */
/**
 * My App API
 * My App API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignOutRequest
 */
export interface SignOutRequest {
    /**
     * 
     * @type {string}
     * @memberof SignOutRequest
     */
    role: SignOutRequestRoleEnum;
}

/**
* @export
* @enum {string}
*/
export enum SignOutRequestRoleEnum {
    Aao = 'aao',
    Approve = 'approve',
    Office = 'office'
}

export function SignOutRequestFromJSON(json: any): SignOutRequest {
    return SignOutRequestFromJSONTyped(json, false);
}

export function SignOutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignOutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
    };
}

export function SignOutRequestToJSON(value?: SignOutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
    };
}


