import { useCallback, useState } from 'react'

export function useSending() {
  const [sending, setSending] = useState(false)

  const send = useCallback(
    async (body: () => Promise<void>) => {
      // すでに送信済みなら何もしない。
      // disabled が効いていなかった時の対応。
      let current = false
      setSending((_c) => {
        current = _c
        return true
      })
      if (current) return
      try {
        await body()
      } finally {
        setSending(false)
      }
    },
    [setSending]
  )

  return { sending, send }
}
