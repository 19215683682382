export enum ColorEnums {
  Primary = '#005B3C',
  Destructive = '#B51D1D',
  Secondary = '#005B3C',
  Warning = '#ea9327',
  Disable = '#8D949A',
}

export enum HoverColorEnums {
  Primary = '#00AC84',
  Destructive = '#e43838',
  Secondary = '#00AC84',
  Warning = '#eca042',
  Disable = '#8D949A',
}
