import clsx from 'clsx'
import React, { FC } from 'react'
import classes from './Container.module.css'

interface ContainerProps {
  bgColor?: 'gray' | 'archive'
  innerWidth?: string
  pb?: number
  print?: boolean
}

export const Container: FC<ContainerProps> = ({ print, ...props }) => {
  const cn = clsx(
    classes.root,
    !print && props.bgColor === 'gray' && classes.root__bgGray,
    !print && props.bgColor === 'archive' && classes.root__bgArchive
  )

  return (
    <div className={cn}>
      <div
        className={classes.inner}
        style={{
          width: `${!print && props.innerWidth ? props.innerWidth : `auto`}`,
          paddingBottom: `${props.pb && props.pb}px`,
        }}
      >
        {props.children}
      </div>
    </div>
  )
}
