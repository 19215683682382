import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import {DevTool} from '@hookform/devtools'
import React, {FC, memo, useCallback, VFC} from 'react'
import {
  Control,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormRegisterReturn,
} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Link as WouterLink} from 'wouter'
import {TextLink} from '../../../common/components/TextLink'
import {FormControlWrapper} from '../../../common/form/FormInput'
import {FormProps} from '../../../common/form/useFormProps'
import {useLang} from '../../../common/i18n/useLang'
import {PasswordInput} from '../../../component/manager/common/atoms/passwordInput/PasswordInput'
import {signIn} from '../../hooks/useApplicant'
import {Button} from '../Button'
import {Title} from '../Title'

type LoginInput = {
  email: string
  password: string
}

const _LoginButtons: VFC = () => {
  const [t] = useTranslation()
  return (
    <Stack>
      <Center>
        <Button role={'primary'} size={'md'} type={'submit'} text={t('ログイン')} w={'200px'} />
      </Center>
      <Center>
        <TextLink text={t('パスワードをお忘れの方はこちら')} to={'/password-reset-request'} />
      </Center>
    </Stack>
  )
}
const LoginButtons = memo(_LoginButtons)

const _SignUpLink: VFC = () => {
  const [t] = useTranslation()
  return (
    <Stack>
      <Center>
        {t('新規にアカウントを作成される方は「新規会員登録」ボタンをクリックしてください。')}
      </Center>
      <Center>
        <WouterLink to={'registrations-request'}>
          <Button role={'secondary'} size={'md'} text={t('新規会員登録')} w={'200px'} />
        </WouterLink>
      </Center>
    </Stack>
  )
}
const SignUpLink = memo(_SignUpLink)

type RowProps = {
  label: string
  children: (props: UseFormRegisterReturn) => JSX.Element
} & FormProps<LoginInput>

const Row: FC<RowProps> = ({ label, children, ...props }) => {
  const [t] = useTranslation()
  return (
    <FormControlWrapper<LoginInput> {...props}>
      {({ id, isInvalid, error, ...inputProps }) => (
        <FormControl
          id={id}
          isRequired={true}
          isInvalid={isInvalid ? true : undefined}
          as={'fieldset'}
          display={'flex'}
          flexDir={'row'}
          alignItems={'baseline'}
        >
          <FormLabel w={'10rem'} fontSize={`14px`} fontWeight={700}>
            {label}
          </FormLabel>
          <Stack>
            {children({ ...inputProps })}
            {error?.message && (
              <FormErrorMessage ml={4} mt={0}>
                {t(error.message)}
              </FormErrorMessage>
            )}
          </Stack>
        </FormControl>
      )}
    </FormControlWrapper>
  )
}

export const LoginForm: FC = () => {
  const { t, language } = useLang()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const methods = useForm<LoginInput>({
    mode: 'onBlur',
  })
  const { control, handleSubmit, setError } = methods

  const href =
    language === `ja`
      ? 'https://www.kyoto-u.ac.jp/ja/international/students1/study1/graduate/graduateinfo/ku-aao'
      : 'https://www.kyoto-u.ac.jp/en/education-campus/education-and-admissions/graduate-degree-programs/for-graduates-of-overseas-universities/for-graduates-of-overseas-universities'

  const login: SubmitHandler<LoginInput> = useCallback(
    async (input) => {
      try {
        await signIn(input.email, input.password)
      } catch (error) {
        switch (error.status) {
          case 404:
            setError('email', {
              type: 'manual',
              message: 'アカウントが見つかりません。',
            })
            break
          case 403:
            setError('password', {
              type: 'manual',
              message: 'パスワードが正しくありません。',
            })
            break
        }
      }
    },
    [setError]
  )

  const onError: SubmitErrorHandler<LoginInput> = useCallback((error, event) => {
    console.info('submit error', error, event)
  }, [])

  return (
    <Stack spacing={8}>
      <Title text={t('ログイン・新規会員登録')} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(login, onError)} noValidate={true}>
          {import.meta.env.DEV && (
            <DevTool control={control as Control<any>} placement={'top-right'} />
          )}
          <Stack spacing={4}>
            <Row
              control={control}
              path={'email'}
              label={t('メールアドレス')}
              options={{
                required: '必須入力です。',
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  message: 'メールアドレスを入力してください。',
                },
              }}
            >
              {(props) => (
                <Input
                  {...props}
                  size={'sm'}
                  type="email"
                  w={'24rem'}
                  autoComplete={'email'}
                  maxLength={200}
                />
              )}
            </Row>

            <Row
              control={control}
              path={'password'}
              label={t('パスワード')}
              skipNormalize={true}
              options={{ required: '必須入力です。' }}
            >
              {(props) => (
                <PasswordInput
                  size={'sm'}
                  {...props}
                  w={'24rem'}
                  autoComplete={'current-password'}
                />
              )}
            </Row>

            <LoginButtons />
          </Stack>
        </form>
      </FormProvider>

      <SignUpLink />

      <div>
        {language === 'en' ? (
          <p>
            Please access the pre-admission support system from{' '}
            <Link color={'#008569'} onClick={onOpen}>
              {'the recommended browsers'}
            </Link>
            .
          </p>
        ) : (
          <p>
            ご利用環境については
            <Link color={'#008569'} onClick={onOpen}>
              {'推奨ブラウザ'}
            </Link>
            を御覧ください。
          </p>
        )}
        <p>
          {t('個人情報の取り扱いに関しては「')}
          <TextLink text={t('個人情報保護方針')} to={'/privacy'} />
          {t('」をご覧下さい。')}
        </p>
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('ご利用環境について')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {language === 'en' ? (
              <p>
                Please use <b>the latest version</b> of the following browsers.
              </p>
            ) : (
              <p>
                以下の推奨ブラウザの<b>最新版</b>をご利用ください。
              </p>
            )}
            <UnorderedList p={2}>
              <ListItem>
                <Link
                  color={'#008569'}
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={'https://www.google.com/chrome/'}
                >
                  Google Chrome
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  color={'#008569'}
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={'https://www.microsoft.com/edge'}
                >
                  Microsoft Edge
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  color={'#008569'}
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={'https://www.mozilla.org/firefox/browsers/'}
                >
                  Firefox
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  color={'#008569'}
                  target={'_blank'}
                  rel={'noreferrer'}
                  href={'https://www.apple.com/safari/'}
                >
                  Safari
                </Link>{' '}
                (macOS only)
              </ListItem>
            </UnorderedList>
          </ModalBody>
          <Center mb={4}>
            <Button onClick={onClose} role={'secondary'} size={'sm'} text={t('閉じる') as string} />
          </Center>
        </ModalContent>
      </Modal>
    </Stack>
  )
}
